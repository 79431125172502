.reportlost-one-line {
  display: flex;
  flex-direction: column;
  gap: 1.7em;
}

.reportlost-container-owner-reward-btns {
  display: flex;
  gap: 1em;
}
.date-time-loss {
  display: flex;
  flex-direction: column;
  gap: 2em;
  background: whitesmoke;
}
.reportlost-bag-container {
  background-color: #c7c7c781;
  width: 100%;
  max-width: 100%;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 11rem; */
}
.loss-details-body {
  width: 80% !important;
  margin-left: 15%;
  border: 1px solid black;
  word-wrap: break-word;
  text-overflow: clip;
  border-radius: 1rem;
}
.reportlost-location-input-cnt {
  display: none;
}

.reportlost-location-input-cnt-lat,
.reportlost-location-input-cnt-lon {
  display: none;
}
.reportlost-location-btns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
  padding: 2rem 2rem 0rem 6rem;
}
.time-picker-container {
  display: flex;
  flex-direction: row;
  gap: 1em;
  width: 55%;
  margin: 0rem 0rem 0rem 9rem;
}
#reward-id {
  width: 183px;
}
.reportlost-container-currency {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.7em;
}
.date-time-loss-input {
  padding: 1em 2em !important;
  border: 1px solid black;
  border-radius: 5px;
}

.date-time-loss-time-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.date-time-loss-btns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
}
.reportlost-container-pet-details-span,
.reportlost-container-pet-parent-details-span {
  display: flex;
  flex-direction: column;
  gap: 1.7em;
}
.reportlost-container-pet-details-span-input-cnt,
.reportlost-container-pet-parent-details-span-input-cnt {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.reportlost-container-pet-details-span-input-cnt > span,
.reportlost-container-pet-parent-details-span-input-cnt > span {
  text-align: left;
  font-size: 1.25rem;
}

.reportlost-container-pet-details-span-input-cnt > input,
.reportlost-container-pet-details-span-input-cnt > select,
.reportlost-container-pet-parent-details-span-input-cnt > input {
  padding: 1rem 1rem !important;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
}

.reportlost-container-pet-details-span-input-cnt > input {
  margin-top: 0 !important;
}
.reportlost-container-pet-details-span > h4,
.reportlost-container-pet-parent-details-span > h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4em;
  margin-top: 1em;
}
.reportlost-container-owner-reward-span-select-cnt {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.genericreportlost-loss-details-btns {
  display: flex;
  gap: 1em;
}
.genericreportlost-loss-details {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.input-pet-details-dropdown {
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  width: 200px;
  height: 20px;
  box-shadow: none;
  font-size: 14px;
  padding: 10px;
  margin-top: 0.5rem;
}
.input-pet-details-dropdown > option {
  padding: 10px;
  font-size: 0.9rem;
}
.genericreportlost-loss-details > textarea {
  padding: 3em;
  border: 1px solid black;
  border-radius: 5px;
  /* width: 327px; */
}

.genericreportlost-location {
  position: relative;
  height: 30em;
}
.genericreportlost-location-map-container {
  position: absolute;
  width: 100%;
  height: 16.5em;
}
.genericreportlost-location-input {
  margin-top: 0px !important;
  padding: 1em 2em !important;
  border: 1px solid black;
  border-radius: 5px;
}
.genericreportlost-location-input-cnt {
  display: none;
  /* position: absolute;
  top: 17em;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em; */
}
.genericreportlost-container-owner-reward-span {
  display: flex;
  flex-direction: column;
  gap: 1.7em;
}
.genericreportlost-container-owner-reward-span > h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4em;
  margin-top: 1em;
}
.genericreportlost-location-input-cnt-lat,
.genericreportlost-location-input-cnt-lon {
  display: none;
  /* display: flex;
  justify-content: space-between;
  align-items: center;*/
}
/* .genericreportlost-container-owner-reward-span-select-cnt {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.genericreportlost-container-owner-reward-span-select-cnt > span {
  text-align: left;
  font-size: 1.25rem;
}
.genericreportlost-container-owner-reward-span-select-cnt > select {
  padding: 1rem 2rem !important;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
}
.genericreportlost-container-owner-reward-span-select-cnt > input {
  margin-top: 0 !important;
} */
.reportlost-container-owner-reward-span-select-cnt {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.reportlost-container-owner-reward-span-select-cnt > span {
  text-align: left;
  font-size: 1.25rem;
}
.reportlost-container-owner-reward-span-select-cnt > select,
.reportlost-container-owner-reward-span-select-cnt > input {
  padding: 1rem !important;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
}
.reportlost-container-owner-reward-span-select-cnt > input {
  margin-top: 0 !important;
}
.genericreportlost-location-input-cnt-lat > span,
.genericreportlost-location-input-cnt-lon > span {
  font-size: 1.1875rem;
}
.genericreportlost-location-btns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
  padding: 2rem 2rem 0rem 6rem;
}

/* .date-time-loss {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.date-time-loss-input {
  padding: 1em 2em !important;
  border: 1px solid black;
  border-radius: 5px;
}
.date-time-loss-time-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}
.date-time-loss-btns {
  display: flex;
  gap: 1em;
} */
/* .date-time-loss-btns {
  display: flex;
  gap: 1em;
  padding: 1rem 14rem;
} */
@media only screen and (max-width: 960px) {
  /* .mat-button-toggle{
        width: 100px;
    } */
  .mat-button-toggle-group {
    flex-wrap: wrap;
    margin: auto !important;
  }
  .googlemaps {
    width: 60% !important;
    height: 300px !important;
  }

  .latlong {
    padding-top: 200px;
  }
  .loss-details-body {
    margin-top: 100px !important;
  }
  .dateandtime {
    margin-top: 20px !important;
  }
}
@media screen and (min-width: 48em) {
  .reportlost-one-line {
    flex-direction: row;
    justify-content: space-between;
  }
  .genericreportlost-loss-details-btns {
    padding: 0 16em;
  }
  .genericreportlost-location-input-cnt {
    top: 18em;
  }

  .genericreportlost-location-input-cnt-lat,
  .genericreportlost-location-input-cnt-lon {
    justify-content: space-evenly;
  }

  .genericreportlost-location-input-cnt-lat > span,
  .genericreportlost-location-input-cnt-lon > span {
    font-size: 1.375rem;
  }
}
.genericreportLost-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
}

.genericreportLost-left-wrapper {
  position: relative;
}

.genericreportLost-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}
.genericreportLost-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}
.genericreportLost-sidebar-container {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}
.genericreportLost-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}
.genericreportLost-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}
.genericreportLost-sidebar-menu-ul-hide {
  display: none;
}

.genericreportLost-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}
.genericreportLost-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* .genericcontainer {
  width: 100%;
} */
.p-info {
  width: 85%;
  border-radius: 5px;
}
@media screen and (min-width: 62.5em) {
  /* .genericreportlost-location-input-cnt-lat,
  .genericreportlost-location-input-cnt-lon {
    justify-content: space-between;
  } */
  .genericreportLost-sidebar-toggle-btn-wrapper {
    display: none;
  }
  .genericreportLost-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }
}

@media screen and (width: 64em) {
  .genericreportLost-right-wrapper {
    margin-left: 11em;
  }
}

@media screen and (min-width: 75em) {
  /* .reportLost-form-container {
    width: 38em;
    margin: 0 24rem;
  } */

  .genericreportLost-right-wrapper {
    margin-left: 20vw;
  }
}

@media screen and (min-width: 78.125em) {
  .genericreportLost-sidebar-menu-link {
    font-size: 1rem;
  }

  .genericreportLost-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 576px) {
  .genericuppercontainer {
    margin-left: 8px !important;
    width: 100% !important;
    margin-right: 0px !important;
  }
  .reportlost-bag-container {
    margin: auto;
    height: auto;
    margin-top: 13rem;
  }
}
@media only screen and (max-width: 510px) {
  .reportlost-bag-container {
    margin: auto;
    height: auto !important;
    /* margin-top: 13rem; */
  }
  .date-time-loss-input {
    width: 100%;
  }
  .time-picker-container {
    display: block;
    gap: 1em;
    margin: auto;
  }
  .date-time-loss {
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: normal;
  }
}
@media only screen and (max-width: 480px) {
  /* .mat-button-toggle{
        width: 50% !important;
      } */
  .loss-details-body {
    width: 100% !important;
    margin: auto;
  }
  .genericuppercontainer {
    width: 95% !important;
    margin-right: -10px !important;
  }
}
@media only screen and (max-width: 360px) {
  .reportlost-bag-container {
    margin: auto;
    height: auto !important;
    /* margin-top: 13rem; */
  }
  .date-time-loss-input {
    width: 100%;
  }
  .time-picker-container {
    display: block;
    gap: 1em;
    margin: auto;
  }
  .date-time-loss {
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: normal;
  }
}
@media only screen and (max-width: 380px) {
  .timerangeofloss {
    margin-top: -30px !important;
  }
  .googlemaps {
    width: 60% !important;
    height: 130px !important;
  }
  .latlong {
    padding-top: 130px;
  }
  .reward_div {
    margin-left: -40px !important;
  }
  .set-time {
    font-size: 22px !important;
  }
}
@media only screen and (max-width: 375px) {
  .reportlost-bag-container {
    margin: auto;
    height: auto !important;
    /* margin-top: 13rem; */
  }
  .date-time-loss-input {
    width: 100%;
  }
  .time-picker-container {
    display: block;
    gap: 1em;
    margin: auto;
  }
  .date-time-loss {
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: normal;
  }
}
@media only screen and (min-width: 22.5em) {
  .date-time-loss-btns {
    display: flex;
    gap: 1em;
    padding: 0rem 3rem;
  }
  .reportlost-container-owner-reward-btns {
    margin: 1em 3em;
    /* margin: 1em 3em; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1em;
  }
}
@media only screen and (min-width: 48em) {
  .date-time-loss-btns {
    display: flex;
    gap: 1em;
    padding: 0rem 12rem;
  }
}

@media only screen and (min-width: 80em) {
  .date-time-loss-btns {
    display: flex;
    gap: 1em;
    padding: 0rem 20rem;
  }
}
@media only screen and (max-width: 650px) {
  /* #lost-calendar-preview {
    margin-left: 15%;
  } */

  .genericreportlostheight {
    height: 100% !important;
  }

  /* .mat-toggle-group-original {
    margin-top: 00px !important;
  } */
}
@media screen and (max-width: 718px) {
  .reportlost-container-currency {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.7em;
  }
}

@media screen and (max-width: 729px) {
  #reward-id {
    width: 230px;
  }
}
