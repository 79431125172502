@import url("https://fonts.googleapis.com/css2?family=Diplomata+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Federant&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gorditas&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fruktur&family=Permanent+Marker&display=swap");
@import url("https://db.onlinewebfonts.com/c/32070eac9c9ad4cb61f359f319bb34ac?family=AbsolutDB");
@import url("https://db.onlinewebfonts.com/c/69034ec1a721c306a74b043f4138a7ed?family=BellCent+NamNum+BT");
@import url("https://db.onlinewebfonts.com/c/0ad0845267e2516d7293374db9d949c5?family=KibbyBoldFont");

.pet-poster-background {
  width: 60%;
  height: 100%;
  margin: auto;
  border: 1px solid rgb(202, 167, 82);
  text-align: center;
}

.header-font {
  font-family: "AbsolutDB";
  color: #f6be07;
}

.header-text {
  font-size: 80px;
  float: center;
  margin: auto;
  margin-bottom: 30px;
}

.mat-card {
  width: 60%;
  margin: auto;
  margin-top: 30px;
  border: 1px solid #cca151;
}

.mat-card-header {
  margin-left: 18%;
}

.lost-info-header {
  font-size: 35px;
  line-break: normal;
}

.lost-info-text {
  color: #cca151;
  font-size: 27px !important;
}

.lost-info-text-address {
  color: #cca151;
  font-size: 21px;
  margin-top: -10px !important;
}

.answers-to-name {
  width: 100%;
  background-color: rgb(102, 89, 32);
  color: white;
  margin: auto;
}

.answers-to-name-header {
  font-family: "KibbyBoldFont";
  font-size: 25px;
  transform: scale(1.6, 1.4);
}

.answers-to-name-text-1 {
  font-family: "AbsolutDB";
  font-size: 22px !important;
  transform: scale(1.3, 1.1);
  font-weight: 400 !important;
  word-wrap: break-word !important;
}

.answers-to-name-text {
  font-family: "KibbyBoldFont";
  font-size: 25px;
  transform: scale(2.5, 2);
  font-weight: 700;
}

.bottom-help-text {
  font-size: 17px;
  transform: scale(1, 1);
}

.bottom-text {
  font-family: "BellCent NamNum BT";
  color: #f6be07;
  font-size: 27px;
  transform: scale(1.2, 1.6);
}

.bottom-text-1 {
  font-family: "BellCent NamNum BT";
  color: #f6be07;
  font-size: 27px;
  transform: scale(1.6, 1.6);
}

.reward-header {
  font-size: 25px;
  transform: scale(1.5, 1.8);
}

.yellow-color {
  color: rgb(202, 167, 82);
}

.button-styling {
  margin: 10px;
  font-size: 16px;
  border: none !important;
  outline: none !important;
}

@media only screen and (max-width: 1120px) {
  .answers-to-name-header {
    font-size: 18px;
  }
  .lost-info-header-res {
    font-size: 28px;
  }
}
@media only screen and (max-width: 922px) {
  .answers-to-name-header {
    font-size: 15px;
  }
  .lost-info-header-res {
    font-size: 22px;
    line-break: normal;
  }
  .postar-res {
    padding: 5px 10px;
    font-size: 10px;
  }
  .button-styling {
    margin: 10px;
    font-size: 18px;
    padding: 5px;
  }
}
@media only screen and (max-width: 768px) {
  .lost-info-header-res {
    font-size: 20px;
    line-break: normal;
  }
  .button-styling {
    width: 100% !important;
  }
  .marginleftmove {
    margin-left: -20px !important;
    text-align: center !important;
  }
  .marginleftmove1 {
    margin-left: 10px !important;
  }
}
@media only screen and (max-width: 690px) {
  .header-text {
    line-height: 4rem;
    font-size: 40px;
  }
  .answers-to-name-header {
    font-size: 8px;
  }
  .button-styling {
    margin: 10px;
    font-size: 18px;
    padding: 5px;
  }
}
@media only screen and (max-width: 400px) {
  .mat-card {
    width: 80%;
  }
  .header-text {
    line-height: 4.5rem;
  }
  .lost-info-header {
    font-size: 20px;
  }

  .answers-to-name {
    padding: 40px !important;
  }

  .answers-to-name-header {
    font-size: 14px !important;
  }
  .answers-to-name-text {
    font-size: 15px;
  }
  .bottom-text-1 {
    font-size: 18px;
  }
  .reward-header {
    padding: 10px 0px 0px 0px;
  }
  .bottom-text {
    padding: 0px 0px 5px 0px;
    font-size: 20px !important;
  }
  .reward-header {
    font-size: 20px !important;
  }
  .answers-to-name-text-1 {
    font-size: 18px !important;
  }
  .marginleftmove1 {
    margin-left: -5px !important;
  }
}
@media only screen and (max-width: 298px) {
  .lost-info-header-respon {
    font-size: 18px;
  }
  .button-styling {
    margin: 12px;
    font-size: 15px;
    padding: 10px;
  }
}
