.genericdetails-main-wrapper {
  width: 100%;
  position: absolute;
  top: 5.3rem;
}

.genericdetails-left-wrapper {
  position: relative;
}

.genericdetails-sidebar-container {
  width: 105%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: 0;
  transition: 350ms;
}
.genericdetails-sidebar-container-hide {
  width: 100%;
  height: 100vh;
  z-index: 800;
  position: absolute;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  left: -100%;
  transition: 350ms;
}
.genericdetails-sidebar-toggle-btn-wrapper {
  padding: 1rem;
  width: 100%;
}

.genericdetails-sidebar-toggle-btn {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.genericdetails-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}
.genericdetails-sidebar-menu-link {
  background-color: transparent;
  border: none;
  padding: 1rem;
  text-align: left;
  font-size: 1.21875rem;
}
.genericdetails-sidebar-menu-ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.genericdetails-sidebar-menu-ul-hide {
  display: none;
}
.genericdetails-sidebar-menu-ul-links {
  border: none;
  background-color: transparent;
  padding: 1rem 1rem 1rem 2rem;
}
.genericdetails-form-container-top {
  /* border: 1px solid brown; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}
.genericDetails-form {
  padding: 1rem;
}
.genericdetails-right-form-label-input-container {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  gap: 1rem;
}
.genericdetails-right-form-inputs {
  padding: 1rem 2rem !important;
  border: 1px solid black;
  border-radius: 5px;
}
.genericdetails-right-form-select {
  background-color: white;
  border: 1px solid black;
  padding: 1rem 2rem !important;
  border-radius: 5px !important;
  /* width: 100%; */
}
.editPetDetails-user-img {
  text-align: center;
  position: relative;
}
.editPetDetails-image {
  width: 15rem;
  height: 15rem;
  margin-bottom: 2rem;
  /* border-radius: 50%; */
  /* object-fit: cover; */
  /* border: 2px solid #fff; */
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); */
}
@media screen and (min-width: 62.5em) {
  .genericdetails-sidebar-toggle-btn-wrapper {
    display: none;
  }
  .genericdetails-sidebar-container-hide {
    position: fixed;
    width: 15%;
    height: 100vh;
    left: 0;
    padding: 1rem;
  }
}

@media screen and (width: 64em) {
  .genericdetails-right-wrapper {
    padding: 4em 12em 0em 25em;
  }
}

@media screen and (min-width: 75em) {
  .genericdetails-form-container {
    width: 38em;
    margin: 0 24rem;
  }
}
@media screen and (min-width: 78.125em) {
  .genericdetails-sidebar-menu-link {
    font-size: 1rem;
  }
  .genericdetails-sidebar-menu-ul-links {
    font-size: 0.875rem;
  }
}
